import { createAction, props } from '@ngrx/store';
import {
  LOAD_REPORTS,
  LOAD_REPORTS_ERROR,
  STORE_REPORTS,
  UPDATE_REPORT_TZ_VALUE,
} from './action-types';

export const loadReportData = createAction(LOAD_REPORTS);
export const loadReportsSuccess = createAction(
  STORE_REPORTS,
  props<{ payload: any[] }>()
);
export const loadReportsFailure = createAction(
  LOAD_REPORTS_ERROR,
  props<{ error: any }>()
);
export const updateReportTZ = createAction(
  UPDATE_REPORT_TZ_VALUE,
  props<{ payload: { reportId: string; newValue: number } }>()
);
